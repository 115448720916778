import React from 'react';

import withLightbox from './settings';
import PageSubtitle from '../Subpage/subtitle';

import { BorderRadius } from '../../utils/variables';

import './gallery.css';

import loadLibrary from '../Loadable/library';
import loadComponents from '../Loadable';

const Img = loadLibrary('gatsby-image');

const LightboxWrapper = loadComponents('lightbox-wrapper');
const LightboxButton = loadComponents('lightbox-button');

class Gallery extends React.Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      selectedImage: 0,
    };
  }

  componentDidMount = () => {
    this._isMounted = true;
    window.addEventListener('keyup', this.handleKeyUp, false);
  };

  componentWillUnmount = () => {
    this._isMounted = false;
    window.removeEventListener('keyup', this.handleKeyUp, false);
  };

  handleClick = (e, i) => {
    e.preventDefault();
    const { openLightbox } = this.props;
    if (this._isMounted) {
      this.setState(
        {
          selectedImage: i,
        },
        () => {
          openLightbox(e);
        },
      );
    }
  };

  goBack = () => {
    if (this._isMounted) {
      const { selectedImage } = this.state;
      this.setState({
        selectedImage: selectedImage > 0 && selectedImage - 1,
      });
    }
  };

  goForward = () => {
    if (this._isMounted) {
      const { selectedImage } = this.state;
      const { images } = this.props;
      this.setState({
        selectedImage: selectedImage < images.length - 1 && selectedImage + 1,
      });
    }
  };

  handleKeyUp = e => {
    e.preventDefault();
    const { keyCode } = e;
    const { showLightbox } = this.state;
    if (showLightbox) {
      if (keyCode === 37) {
        // Left Arrow Key
        this.goBack();
      }
      if (keyCode === 39) {
        // Right Arrow Key
        this.goForward();
      }
    }
  };

  render() {
    const { images, title, showLightbox, closeLightbox } = this.props;
    const { selectedImage } = this.state;
    return (
      <>
        <div className="subpageComponent">
          {title && <PageSubtitle title={title} />}
          <div className="galleryOuter" style={outerStyle}>
            {images.map((img, i) => (
              <div key={img.localFile.publicURL} style={itemStyle}>
                <a
                  className="btnLink"
                  href={img.localFile.publicURL}
                  onClick={e => this.handleClick(e, i)}
                  style={{
                    display: `inline-block`,
                    lineHeight: 1,
                    verticalAlign: `top`,
                  }}
                >
                  <Img
                    image={img.localFile.galleryThumbnail.gatsbyImageData}
                    alt="Image Thumbnail"
                  />
                </a>
              </div>
            ))}
          </div>
        </div>

        <LightboxWrapper
          showLightbox={showLightbox}
          closeLightbox={closeLightbox}
        >
          <div style={imageStyle}>
            <Img
              image={
                images[selectedImage].localFile.galleryImage.gatsbyImageData
              }
              alt="Full sized image"
            />
          </div>
          <div style={controlStyle}>
            <div>
              <LightboxButton
                prev
                onClick={this.goBack}
                disabled={selectedImage === 0}
                style={{ marginRight: `0.625rem` }}
              >
                Previous
              </LightboxButton>
              <LightboxButton
                next
                onClick={this.goForward}
                disabled={selectedImage === images.length - 1}
              >
                Next
              </LightboxButton>
            </div>
          </div>
        </LightboxWrapper>
      </>
    );
  }
}

export default withLightbox(Gallery);

const controlStyle = {
  display: `flex`,
  justifyContent: `space-between`,
  marginTop: `1.875rem`,
};

const imageStyle = {
  borderRadius: BorderRadius,
  overflow: `hidden`,
};

const itemStyle = {
  position: `relative`,
  borderRadius: BorderRadius,
  overflow: `hidden`,
  cursor: `pointer`,
};

const outerStyle = {
  display: `grid`,
  gridGap: `15px`,
};
