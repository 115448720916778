import React from 'react';

function withLightbox(WrappedComponent) {
  class LightboxSettings extends React.Component {
    _isMounted = false;

    constructor(props) {
      super(props);
      this.state = {
        showLightbox: false,
      };
    }

    componentDidMount() {
      this._isMounted = true;
      window.addEventListener('keyup', this.handleKeyUp, false);
    }

    componentWillUnmount() {
      this._isMounted = false;
      window.removeEventListener('keyup', this.handleKeyUp, false);
    }

    toggleLightbox = () => {
      const { showLightbox } = this.state;
      if (this._isMounted) {
        this.setState({ showLightbox: !showLightbox });
      }

      if (showLightbox) {
        document.documentElement.classList.remove('modalOpen');
      } else {
        document.documentElement.classList.add('modalOpen');
      }
    };

    openLightbox = () => {
      if (this._isMounted) {
        this.setState({ showLightbox: true });
      }
      document.documentElement.classList.add('modalOpen');
    };

    closeLightbox = () => {
      if (this._isMounted) {
        this.setState({ showLightbox: false });
      }
      document.documentElement.classList.remove('modalOpen');
    };

    handleKeyUp = e => {
      const { showLightbox } = this.state;
      const { keyCode } = e;
      if (showLightbox) {
        if (keyCode === 27) {
          if (this._isMounted) {
            this.setState({ showLightbox: false });
          }
          document.documentElement.classList.remove('modalOpen');
        }
      }
    };

    render() {
      const { showLightbox } = this.state;
      return (
        <WrappedComponent
          {...this.props}
          showLightbox={showLightbox}
          toggleLightbox={this.toggleLightbox}
          openLightbox={this.openLightbox}
          closeLightbox={this.closeLightbox}
        />
      );
    }
  }
  return LightboxSettings;
}

export default withLightbox;
