import React from 'react';
import parse from 'html-react-parser';

import Placeholder from '../Placeholder';

import loadComponents from '../Loadable';

const Title = loadComponents('h2');

export default function PageSubtitle({ title, margin, style }) {
  const titleMargin = margin || `3.75rem`;
  return (
    <Title
      size="40px"
      margin={titleMargin}
      line={1}
      fallback={
        <Placeholder
          width="300px"
          height="40px"
          colour="#222"
          style={{ marginBottom: titleMargin }}
        />
      }
      style={style}
    >
      {parse(title)}
    </Title>
  );
}
